import  * as actionTypes from '../actions/types'
import { updateObject } from 'utils';


const initialState = {
    job: {},
    jobParsing: {},
    jobRevealing: {},
    jobRevealingResult: {},
    jobSearching: {},
    jobParsingCopilot: {},
    jobAskingResponse: {},
    jobAsking: {},
}

const fetchJobsRequest = (state, action) => {
    return updateObject( state, {
        jobSearching: { r: true, payload: state.jobs }
    });
}

const fetchJobsSuccess = (state, action) => {
    return updateObject( state, {
        jobSearching: { s: true, payload: action.jobs }
    });
}

const fetchJobsFail = (state, action) => {
    return updateObject( state, {
        jobSearching: { f: true }
    });
}

const postJobParsingRequest = (state, action) => {
    return updateObject( state, {
        job: { r: true, payload: state.job }
    });
}

const postJobParsingSuccess = (state, action) => {
    return updateObject( state, {
        job: { s: true, payload: action.profile }
    });
}

const postJobParsingFail = (state, action) => {
    return updateObject( state, {
        job: { f: true }
    });
}

const getJobParsingRequest = (state, action) => {
    return updateObject( state, {
        jobParsing: { r: true, payload: state.jobParsing }
    });
}

const getJobParsingSuccess = (state, action) => {
    return updateObject( state, {
        jobParsing: { s: true, payload: action.res }
    });
}

const getJobParsingFail = (state, action) => {
    return updateObject( state, {
        jobParsing: { f: true }
    });
}

const postJobRevealingRequest = (state, action) => {
    return updateObject( state, {
        jobRevealing: { r: true, payload: state.jobRevealing }
    });
}

const postJobRevealingSuccess = (state, action) => {
    return updateObject( state, {
        jobRevealing: { s: true, payload: action.res }
    });
}

const postJobRevealingFail = (state, action) => {
    return updateObject( state, {
        jobRevealing: { f: true }
    });
}

const getJobRevealingRequest = (state, action) => {
    return updateObject( state, {
        jobRevealingResult: { r: true, payload: state.jobRevealingResult }
    });
}

const getJobRevealingSuccess = (state, action) => {
    return updateObject( state, {
        jobRevealingResult: { s: true, payload: action.res }
    });
}

const getJobRevealingFail = (state, action) => {
    return updateObject( state, {
        jobRevealingResult: { f: true }
    });
}

const postJobParsingCopilotRequest = (state, action) => {
    return updateObject( state, {
        jobParsingCopilot: { r: true, payload: state.res }
    });
}

const postJobParsingCopilotSuccess = (state, action) => {
    return updateObject( state, {
        jobParsingCopilot: { s: true, payload: action.res }
    });
}

const postJobParsingCopilotFail = (state, action) => {
    return updateObject( state, {
        jobParsingCopilot: { f: true }
    });
}

const postJobAskingRequest = (state, action) => {
    return updateObject( state, {
        jobAskingResponse: { r: true, payload: state.res }
    });
}

const postJobAskingSuccess = (state, action) => {
    return updateObject( state, {
        jobAskingResponse: { s: true, payload: action.res }
    });
}

const postJobAskingFail = (state, action) => {
    return updateObject( state, {
        jobAskingResponse: { f: true }
    });
}

const getJobAskingRequest = (state, action) => {
    return updateObject( state, {
        jobAsking: { r: true, payload: state.jobAsking }
    });
}

const getJobAskingSuccess = (state, action) => {
    return updateObject( state, {
        jobAsking: { s: true, payload: action.res }
    });
}

const getJobAskingFail = (state, action) => {
    return updateObject( state, {
        jobAsking: { f: true }
    });
}

const jobReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.POST_JOB_PARSING_REQUEST: return postJobParsingRequest( state, action );
        case actionTypes.POST_JOB_PARSING_SUCCESS: return postJobParsingSuccess( state, action );
        case actionTypes.POST_JOB_PARSING_FAIL: return postJobParsingFail( state, action );
        case actionTypes.GET_JOB_PARSING_REQUEST: return getJobParsingRequest( state, action );
        case actionTypes.GET_JOB_PARSING_SUCCESS: return getJobParsingSuccess( state, action );
        case actionTypes.GET_JOB_PARSING_FAIL: return getJobParsingFail( state, action );
        case actionTypes.POST_JOB_REVEALING_REQUEST: return postJobRevealingRequest( state, action );
        case actionTypes.POST_JOB_REVEALING_SUCCESS: return postJobRevealingSuccess( state, action );
        case actionTypes.POST_JOB_REVEALING_FAIL: return postJobRevealingFail( state, action );
        case actionTypes.GET_JOB_REVEALING_REQUEST: return getJobRevealingRequest( state, action );
        case actionTypes.GET_JOB_REVEALING_SUCCESS: return getJobRevealingSuccess( state, action );
        case actionTypes.GET_JOB_REVEALING_FAIL: return getJobRevealingFail( state, action );
        case actionTypes.FETCH_JOBS_REQUEST: return fetchJobsRequest( state, action );
        case actionTypes.FETCH_JOBS_SUCCESS: return fetchJobsSuccess( state, action );
        case actionTypes.FETCH_JOBS_FAIL: return fetchJobsFail( state, action );
        case actionTypes.POST_JOB_PARSING_COPILOT_REQUEST: return postJobParsingCopilotRequest( state, action );
        case actionTypes.POST_JOB_PARSING_COPILOT_SUCCESS: return postJobParsingCopilotSuccess( state, action );
        case actionTypes.POST_JOB_PARSING_COPILOT_FAIL: return postJobParsingCopilotFail( state, action );

        case actionTypes.POST_JOB_ASKING_REQUEST: return postJobAskingRequest( state, action );
        case actionTypes.POST_JOB_ASKING_SUCCESS: return postJobAskingSuccess( state, action );
        case actionTypes.POST_JOB_ASKING_FAIL: return postJobAskingFail( state, action );

        case actionTypes.GET_JOB_ASKING_REQUEST: return getJobAskingRequest( state, action );
        case actionTypes.GET_JOB_ASKING_SUCCESS: return getJobAskingSuccess( state, action );
        case actionTypes.GET_JOB_ASKING_FAIL: return getJobAskingFail( state, action );
        default: return state;
    }
};

export default jobReducer;