import  * as actionTypes from '../actions/types'
import { updateObject } from 'utils';


const initialState = {
    documentLinking: {},
    linkingResult: {},
    imagingResult: {},
    textImaging: {},
    textTagging: {},
    textTaggingResult: {},
}


const postDocumentLinkingRequest = (state, action) => {
    return updateObject( state, {
        documentLinking: { r: true, payload: state.documentLinking }
    });
}

const postDocumentLinkingSuccess = (state, action) => {
    return updateObject( state, {
        documentLinking: { s: true, payload: action.profile }
    });
}

const postDocumentLinkingFail = (state, action) => {
    return updateObject( state, {
        documentLinking: { f: true }
    });
}

const postTextImagingRequest = (state, action) => {
    return updateObject( state, {
        textImaging: { r: true, payload: state.textImaging }
    });
}

const postTextImagingSuccess = (state, action) => {
    return updateObject( state, {
        textImaging: { s: true, payload: action.profile }
    });
}

const postTextImagingFail = (state, action) => {
    return updateObject( state, {
        textImaging: { f: true }
    });
}

const getDocumentLinkingRequest = (state, action) => {
    return updateObject( state, {
        linkingResult: { r: true, payload: state.linkingResult }
    });
}

const getDocumentLinkingSuccess = (state, action) => {
    return updateObject( state, {
        linkingResult: { s: true, payload: action.res }
    });
}

const getDocumentLinkingFail = (state, action) => {
    return updateObject( state, {
        linkingResult: { f: true }
    });
}

const getTextImagingRequest = (state, action) => {
    return updateObject( state, {
        imagingResult: { r: true, payload: state.imagingResult }
    });
}

const getTextImagingSuccess = (state, action) => {
    return updateObject( state, {
        imagingResult: { s: true, payload: action.res }
    });
}

const getTextImagingFail = (state, action) => {
    return updateObject( state, {
        imagingResult: { f: true }
    });
}

const postTextTaggingRequest = (state, action) => {
    return updateObject( state, {
        textTagging: { r: true, payload: state.textTagging }
    });
}

const postTextTaggingSuccess = (state, action) => {
    return updateObject( state, {
        textTagging: { s: true, payload: action.res }
    });
}

const postTextTaggingFail = (state, action) => {
    return updateObject( state, {
        textTagging: { f: true }
    });
}

const getTextTaggingRequest = (state, action) => {
    return updateObject( state, {
        textTaggingResult: { r: true, payload: state.textTaggingResult }
    });
}

const getTextTaggingSuccess = (state, action) => {
    return updateObject( state, {
        textTaggingResult: { s: true, payload: action.res }
    });
}

const getTextTaggingFail = (state, action) => {
    return updateObject( state, {
        textTaggingResult: { f: true }
    });
}

const documentReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.POST_DOCUMENT_LINKING_REQUEST: return postDocumentLinkingRequest( state, action );
        case actionTypes.POST_DOCUMENT_LINKING_SUCCESS: return postDocumentLinkingSuccess( state, action );
        case actionTypes.POST_DOCUMENT_LINKING_FAIL: return postDocumentLinkingFail( state, action );
        case actionTypes.GET_DOCUMENT_LINKING_REQUEST: return getDocumentLinkingRequest( state, action );
        case actionTypes.GET_DOCUMENT_LINKING_SUCCESS: return getDocumentLinkingSuccess( state, action );
        case actionTypes.GET_DOCUMENT_LINKING_FAIL: return getDocumentLinkingFail( state, action );
        case actionTypes.POST_TEXT_IMAGING_REQUEST: return postTextImagingRequest( state, action );
        case actionTypes.POST_TEXT_IMAGING_SUCCESS: return postTextImagingSuccess( state, action );
        case actionTypes.POST_TEXT_IMAGING_FAIL: return postTextImagingFail( state, action );
        case actionTypes.GET_TEXT_IMAGING_REQUEST: return getTextImagingRequest( state, action );
        case actionTypes.GET_TEXT_IMAGING_SUCCESS: return getTextImagingSuccess( state, action );
        case actionTypes.GET_TEXT_IMAGING_FAIL: return getTextImagingFail( state, action );
        case actionTypes.POST_TEXT_TAGGING_REQUEST: return postTextTaggingRequest( state, action );
        case actionTypes.POST_TEXT_TAGGING_SUCCESS: return postTextTaggingSuccess( state, action );
        case actionTypes.POST_TEXT_TAGGING_FAIL: return postTextTaggingFail( state, action );

        case actionTypes.GET_TEXT_TAGGING_REQUEST: return getTextTaggingRequest( state, action );
        case actionTypes.GET_TEXT_TAGGING_SUCCESS: return getTextTaggingSuccess( state, action );
        case actionTypes.GET_TEXT_TAGGING_FAIL: return getTextTaggingFail( state, action );
        default: return state;
    }
};

export default documentReducer;