export const POST_PROFILE_FILE_REQUEST = 'POST_PROFILE_FILE_REQUEST';
export const POST_PROFILE_FILE_SUCCESS = 'POST_PROFILE_FILE_SUCCESS';
export const POST_PROFILE_FILE_FAIL = 'POST_PROFILE_FILE_FAIL';

export const GET_PROFILE_INDEXING_REQUEST = 'GET_PROFILE_INDEXING_REQUEST';
export const GET_PROFILE_INDEXING_SUCCESS = 'GET_PROFILE_INDEXING_SUCCESS';
export const GET_PROFILE_INDEXING_FAIL = 'GET_PROFILE_INDEXING_FAIL';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const LOGOUT = 'LOGOUT';
export const AUTOLOGIN = 'AUTOLOGIN';

export const GET_JOB_PARSING_REQUEST = 'GET_JOB_PARSING_REQUEST';
export const GET_JOB_PARSING_SUCCESS = 'GET_JOB_PARSING_SUCCESS';
export const GET_JOB_PARSING_FAIL = 'GET_JOB_PARSING_FAIL';

export const POST_JOB_PARSING_REQUEST = 'POST_JOB_PARSING_REQUEST';
export const POST_JOB_PARSING_SUCCESS = 'POST_JOB_PARSING_SUCCESS';
export const POST_JOB_PARSING_FAIL = 'POST_JOB_PARSING_FAIL';

export const POST_JOB_REVEALING_REQUEST = 'POST_JOB_REVEALING_REQUEST';
export const POST_JOB_REVEALING_SUCCESS = 'POST_JOB_REVEALING_SUCCESS';
export const POST_JOB_REVEALING_FAIL = 'POST_JOB_REVEALING_FAIL';

export const GET_JOB_REVEALING_REQUEST = 'GET_JOB_REVEALING_REQUEST';
export const GET_JOB_REVEALING_SUCCESS = 'GET_JOB_REVEALING_SUCCESS';
export const GET_JOB_REVEALING_FAIL = 'GET_JOB_REVEALING_FAIL';

export const FETCH_JOBS_REQUEST = 'FETCH_JOBS_REQUEST';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAIL = 'FETCH_JOBS_FAIL';

export const GET_PROFILE_SEARCHING_REQUEST = 'GET_PROFILE_SEARCHING_REQUEST';
export const GET_PROFILE_SEARCHING_SUCCESS = 'GET_PROFILE_SEARCHING_SUCCESS';
export const GET_PROFILE_SEARCHING_FAIL = 'GET_PROFILE_SEARCHING_FAIL';

export const POST_DOCUMENT_LINKING_REQUEST = 'POST_DOCUMENT_LINKING_REQUEST';
export const POST_DOCUMENT_LINKING_SUCCESS = 'POST_DOCUMENT_LINKING_SUCCESS';
export const POST_DOCUMENT_LINKING_FAIL = 'POST_DOCUMENT_LINKING_FAIL';

export const GET_DOCUMENT_LINKING_REQUEST = 'GET_DOCUMENT_LINKING_REQUEST';
export const GET_DOCUMENT_LINKING_SUCCESS = 'GET_DOCUMENT_LINKING_SUCCESS';
export const GET_DOCUMENT_LINKING_FAIL = 'GET_DOCUMENT_LINKING_FAIL';

export const GET_TEXT_IMAGING_REQUEST = 'GET_TEXT_IMAGING_REQUEST';
export const GET_TEXT_IMAGING_SUCCESS = 'GET_TEXT_IMAGING_SUCCESS';
export const GET_TEXT_IMAGING_FAIL = 'GET_TEXT_IMAGING_FAIL';

export const POST_TEXT_IMAGING_REQUEST = 'POST_TEXT_IMAGING_REQUEST';
export const POST_TEXT_IMAGING_SUCCESS = 'POST_TEXT_IMAGING_SUCCESS';
export const POST_TEXT_IMAGING_FAIL = 'POST_TEXT_IMAGING_FAIL';

export const POST_JOB_PARSING_COPILOT_REQUEST = 'POST_JOB_PARSING_COPILOT_REQUEST';
export const POST_JOB_PARSING_COPILOT_SUCCESS = 'POST_JOB_PARSING_COPILOT_SUCCESS';
export const POST_JOB_PARSING_COPILOT_FAIL = 'POST_JOB_PARSING_COPILOT_FAIL';

export const GET_RECRUITER_COPILOT_REQUEST = 'GET_RECRUITER_COPILOT_REQUEST';
export const GET_RECRUITER_COPILOT_SUCCESS = 'GET_RECRUITER_COPILOT_SUCCESS';
export const GET_RECRUITER_COPILOT_FAIL = 'GET_RECRUITER_COPILOT_FAIL';

export const POST_TEXT_TAGGING_REQUEST = 'POST_TEXT_TAGGING_REQUEST';
export const POST_TEXT_TAGGING_SUCCESS = 'POST_TEXT_TAGGING_SUCCESS';
export const POST_TEXT_TAGGING_FAIL = 'POST_TEXT_TAGGING_FAIL';

export const GET_TEXT_TAGGING_REQUEST = 'GET_TEXT_TAGGING_REQUEST';
export const GET_TEXT_TAGGING_SUCCESS = 'GET_TEXT_TAGGING_SUCCESS';
export const GET_TEXT_TAGGING_FAIL = 'GET_TEXT_TAGGING_FAIL';

export const POST_JOB_ASKING_REQUEST = 'POST_JOB_ASKING_REQUEST';
export const POST_JOB_ASKING_SUCCESS = 'POST_JOB_ASKING_SUCCESS';
export const POST_JOB_ASKING_FAIL = 'POST_JOB_ASKING_FAIL';

export const GET_JOB_ASKING_REQUEST = 'GET_JOB_ASKING_REQUEST';
export const GET_JOB_ASKING_SUCCESS = 'GET_JOB_ASKING_SUCCESS';
export const GET_JOB_ASKING_FAIL = 'GET_JOB_ASKING_FAIL';

export const POST_PROFILE_UNFOLDING_REQUEST = 'POST_PROFILE_UNFOLDING_REQUEST';
export const POST_PROFILE_UNFOLDING_SUCCESS = 'POST_PROFILE_UNFOLDING_SUCCESS';
export const POST_PROFILE_UNFOLDING_FAIL = 'POST_PROFILE_UNFOLDING_FAIL';

export const GET_PROFILE_UNFOLDING_REQUEST = 'GET_PROFILE_UNFOLDING_REQUEST';
export const GET_PROFILE_UNFOLDING_SUCCESS = 'GET_PROFILE_UNFOLDING_SUCCESS';
export const GET_PROFILE_UNFOLDING_FAIL = 'GET_PROFILE_UNFOLDING_FAIL';

export const POST_PROFILE_ASKING_REQUEST = 'POST_PROFILE_ASKING_REQUEST';
export const POST_PROFILE_ASKING_SUCCESS = 'POST_PROFILE_ASKING_SUCCESS';
export const POST_PROFILE_ASKING_FAIL = 'POST_PROFILE_ASKING_FAIL';

export const GET_PROFILE_ASKING_REQUEST = 'GET_PROFILE_ASKING_REQUEST';
export const GET_PROFILE_ASKING_SUCCESS = 'GET_PROFILE_ASKING_SUCCESS';
export const GET_PROFILE_ASKING_FAIL = 'GET_PROFILE_ASKING_FAIL';
