import  * as actionTypes from '../actions/types'
import { updateObject } from 'utils';


const initialState = {
    profileFile: {},
    profileIndexing: {},
    profileSearching: {},
    recruiterCopilot: {},
    profileAsking: {},
    profileAskingResult: {},
    profileUnfoldingFile: {},
    profileUnfolding: {}
}

const postProfileFileRequest = (state, action) => {
    return updateObject( state, {
        profileFile: { r: true, payload: state.profileFile }
    });
}

const postProfileFileSuccess = (state, action) => {
    return updateObject( state, {
        profileFile: { s: true, payload: action.res }
    });
}

const postProfileFileFail = (state, action) => {
    return updateObject( state, {
        profileFile: { f: true }
    });
}

const getProfileIndexingRequest = (state, action) => {
    return updateObject( state, {
        profileIndexing: { r: true, payload: state.profileIndexing }
    });
}

const getProfileIndexingSuccess = (state, action) => {
    return updateObject( state, {
        profileIndexing: { s: true, payload: action.profileIndexing }
    });
}

const getProfileIndexingFail = (state, action) => {
    return updateObject( state, {
        profileIndexing: { f: true }
    });
}

const getProfileSearchingRequest = (state, action) => {
    return updateObject( state, {
        profileSearching: { r: true, payload: state.profiles }
    });
}

const getProfileSearchingSuccess = (state, action) => {
    return updateObject( state, {
        profileSearching: { s: true, payload: action.profiles }
    });
}

const getProfileSearchingFail = (state, action) => {
    return updateObject( state, {
        profileSearching: { f: true }
    });
}

const getRecruiterCopilotRequest = (state, action) => {
    return updateObject( state, {
        recruiterCopilot: { r: true, payload: state.res }
    });
}

const getRecruiterCopilotSuccess = (state, action) => {
    return updateObject( state, {
        recruiterCopilot: { s: true, payload: action.res }
    });
}

const getRecruiterCopilotFail = (state, action) => {
    return updateObject( state, {
        recruiterCopilot: { f: true }
    });
}

const postProfileAskingRequest = (state, action) => {
    return updateObject( state, {
        profileAsking: { r: true, payload: state.profileAsking }
    });
}

const postProfileAskingSuccess = (state, action) => {
    return updateObject( state, {
        profileAsking: { s: true, payload: action.res }
    });
}

const postProfileAskingFail = (state, action) => {
    return updateObject( state, {
        profileAsking: { f: true }
    });
}

const getProfileAskingRequest = (state, action) => {
    return updateObject( state, {
        profileAskingResult: { r: true, payload: state.profileAskingResult }
    });
}

const getProfileAskingSuccess = (state, action) => {
    return updateObject( state, {
        profileAskingResult: { s: true, payload: action.res }
    });
}

const getProfileAskingFail = (state, action) => {
    return updateObject( state, {
        profileAskingResult: { f: true }
    });
}
const postProfileUnfoldingRequest = (state, action) => {
    return updateObject( state, {
        profileUnfoldingFile: { r: true, payload: state.profileUnfoldingFile }
    });
}

const postProfileUnfoldingSuccess = (state, action) => {
    return updateObject( state, {
        profileUnfoldingFile: { s: true, payload: action.res }
    });
}

const postProfileUnfoldingFail = (state, action) => {
    return updateObject( state, {
        profileUnfoldingFile: { f: true }
    });
}

const getProfileUnfoldingRequest = (state, action) => {
    return updateObject( state, {
        profileUnfolding: { r: true, payload: state.profileUnfolding }
    });
}

const getProfileUnfoldingSuccess = (state, action) => {
    return updateObject( state, {
        profileUnfolding: { s: true, payload: action.profileUnfolding }
    });
}

const getProfileUnfoldingFail = (state, action) => {
    return updateObject( state, {
        profileUnfolding: { f: true }
    });
}

const profileReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.POST_PROFILE_FILE_REQUEST: return postProfileFileRequest( state, action );
        case actionTypes.POST_PROFILE_FILE_SUCCESS: return postProfileFileSuccess( state, action );
        case actionTypes.POST_PROFILE_FILE_FAIL: return postProfileFileFail( state, action );
        case actionTypes.GET_PROFILE_INDEXING_REQUEST: return getProfileIndexingRequest( state, action );
        case actionTypes.GET_PROFILE_INDEXING_SUCCESS: return getProfileIndexingSuccess( state, action );
        case actionTypes.GET_PROFILE_INDEXING_FAIL: return getProfileIndexingFail( state, action );
        case actionTypes.GET_PROFILE_SEARCHING_REQUEST: return getProfileSearchingRequest( state, action );
        case actionTypes.GET_PROFILE_SEARCHING_SUCCESS: return getProfileSearchingSuccess( state, action );
        case actionTypes.GET_PROFILE_SEARCHING_FAIL: return getProfileSearchingFail( state, action );
        case actionTypes.GET_RECRUITER_COPILOT_REQUEST: return getRecruiterCopilotRequest( state, action );
        case actionTypes.GET_RECRUITER_COPILOT_SUCCESS: return getRecruiterCopilotSuccess( state, action );
        case actionTypes.GET_RECRUITER_COPILOT_FAIL: return getRecruiterCopilotFail( state, action );

        case actionTypes.POST_PROFILE_ASKING_REQUEST: return postProfileAskingRequest( state, action );
        case actionTypes.POST_PROFILE_ASKING_SUCCESS: return postProfileAskingSuccess( state, action );
        case actionTypes.POST_PROFILE_ASKING_FAIL: return postProfileAskingFail( state, action );

        case actionTypes.GET_PROFILE_ASKING_REQUEST: return getProfileAskingRequest( state, action );
        case actionTypes.GET_PROFILE_ASKING_SUCCESS: return getProfileAskingSuccess( state, action );
        case actionTypes.GET_PROFILE_ASKING_FAIL: return getProfileAskingFail( state, action );
        
        case actionTypes.POST_PROFILE_UNFOLDING_REQUEST: return postProfileUnfoldingRequest( state, action );
        case actionTypes.POST_PROFILE_UNFOLDING_SUCCESS: return postProfileUnfoldingSuccess( state, action );
        case actionTypes.POST_PROFILE_UNFOLDING_FAIL: return postProfileUnfoldingFail( state, action );

        case actionTypes.GET_PROFILE_UNFOLDING_REQUEST: return getProfileUnfoldingRequest( state, action );
        case actionTypes.GET_PROFILE_UNFOLDING_SUCCESS: return getProfileUnfoldingSuccess( state, action );
        case actionTypes.GET_PROFILE_UNFOLDING_FAIL: return getProfileUnfoldingFail( state, action );
        default: return state;
    }
};

export default profileReducer;